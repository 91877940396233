import React, { useState } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Button from "../components/styles/Button"


export default function CaseDetail({ pageContext: { node,nextPage },location }) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[200px] pb-12 sm:pt-24">
                <ul className="flex items-center flex-wrap text-gray">
                    <li><a className="hover:text-black" href="/">首页</a>&nbsp;&gt;&nbsp;</li>
                    <li><a className="hover:text-black" href={node.case_category.url}>{node.case_category.title}</a>&nbsp;&gt;&nbsp;</li>
                    <li><a className="text-black hover:text-black" href={node.url}>{node.title}</a></li>
                </ul>
            </div>
            <article className="pb-[170px] case sm:pb-24">
                <div className="container mx-auto">
                    <h1>{node.title}</h1>
                    <div className="flex justify-between items-start mb-10 xl:flex-col">
                        <div className="w-[380px] xl:w-full" dangerouslySetInnerHTML={{__html: node.caseFeature}}></div>
                        <GatsbyImage className="w-[944px] xl:w-full bg-light" image={getImage(node.casePicture.localFile)} alt="article picture"/>
                    </div>
                   
                </div>
                <div dangerouslySetInnerHTML={{__html: node.caseContent}}/>
            </article>
            <div className="flex max-w-[1672px] mx-auto w-full px-4 mb-[70px] xl:flex-col">
                <div className="bg-black w-[595px] pt-[56px] pr-[175px] pb-[72px] pl-[100px] xl:w-full xl:px-5">
                    <p className="text-white text-[40px] leading-snug pb-10">返回案列应用列表</p>
                    <Button variant="white" href={node.case_category.url}>返回</Button>
                </div>
                <div className="bg-light pt-[56px] px-[68px] pb-[48px] w-[calc(100%-595px)] xl:w-full xl:px-5">
                    <p className="pb-[55px] text-3xl leading-snug truncate">下一篇：{nextPage.title}</p>
                    <Button variant="black" href={nextPage.url}>了解更多</Button>
                </div>
            </div>
        </Layout>
    )
}

